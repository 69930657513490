import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import Button from '@libs/Buttons/Button'
import facebook from './img/facebook'
import instagram from './img/instagram'
import linkedin from './img/linkedin'
import logo from './img/logo'
import classes from './classes.module.scss'

export default function Footer() {
  const { pathname } = useLocation()
  const { changeSettings } = useModal()
  const { t } = useTranslation()

  const onClickHandler = (e) => {
    const {
      type,
      mark,
    } = e.target.dataset

    changeSettings(true, {
      type,
      mark,
    })
  }

  const footerContent = <div className={classes.footer}>
    <div className="landing_component_wrapper">
      <div className={classes.content}>
        <ul>
          <li><Link to="/privacy_policy">{t('navigation:privacy_policy')}</Link></li>
          {/* <li><Link to="/public_offer">{t('navigation:public_offer')}</Link></li> */}
          <li><Link to="/website_conditions">{t('navigation:website_conditions')}</Link></li>
          <li><Link to="/payment">{t('navigation:payment')}</Link></li>
          <li><Link to="/vacancies">{t('navigation:vacancies')}</Link></li>
          <li><Link to="/advertising">{t('navigation:marketing')}</Link></li>
        </ul>
        <ul>
          <li className={classes.social_networks}>
            <a href="https://www.facebook.com/yolostudyonline" target="_blank" rel="noreferrer">
              <LazyLoad>
                <img src={facebook} alt="facebook" width="26" height="26" />
              </LazyLoad>
            </a>
            <a href="https://www.instagram.com/yolostudyonline/" target="_blank" rel="noreferrer">
              <LazyLoad>
                <img src={instagram} alt="instagram" width="26" height="26" />
              </LazyLoad>
            </a>
            <a href="https://www.linkedin.com/company/yolo-study-online/about/" target="_blank" rel="noreferrer">
              <LazyLoad>
                <img src={linkedin} alt="linkedin" width="28" height="26" />
              </LazyLoad>
            </a>
          </li>
          <li><a href="mailto:yolostudyonline@gmail.com">yolostudyonline@gmail.com</a></li>
          <li><a href="tel:+380955838283">+38 095 583 82 83</a></li>
        </ul>
        <Button
          color="purple"
          source="landing"
          onClick={onClickHandler}
          data-type="registration"
          data-mark="Footer"
        >
          {t('buttons:try_free')}
        </Button>
        <div className={classes.images}>
          <div>
            <LazyLoad>
              <img src={logo} alt="logo" width="137" height="69" />
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  </div>

  return (
    <footer>
      {
        pathname === '/payment' || pathname === '/vacancies' || pathname === '/advertising'
          ? footerContent
          : <LazyLoad offset={100}>
            {footerContent}
          </LazyLoad>
      }
    </footer>
  )
}