import React from 'react'
import './loader.scss'

const Loader = ({
  backgroundColor,
  color,
}) => {
  return <div
    style={{ backgroundColor }}
    className="Loader_wrapper_full"
  >
    <svg
      width="323"
      height="103"
      viewBox="0 0 323 103"
      fill="#430000"
      xmlns="http://www.w3.org/2000/svg"
      className="Loader rotate-vert-center"
    >
      <path
        d="M275.065 102.388C269.542 102.218 263.713 101.098 258.088 98.7558C255.886 97.8392 253.954 96.4813 251.819 95.4969C250.159 94.7161 248.566 93.7316 247.279 92.6453C245.347 90.9819 243.483 89.1488 241.62 87.3496C238.671 84.532 236.469 81.1713 234.537 77.7087C232.572 74.2122 231.013 70.4101 230.098 66.3705C229.556 63.9942 229.692 62.0592 230.776 59.9545C232.47 56.6956 237.587 55.6432 240.772 57.4085C242.636 58.4269 243.687 59.8866 244.229 61.9574C245.584 67.1852 248.024 71.9038 251.48 76.0453C252.667 77.4711 254.158 78.6592 255.547 79.9153C258.834 82.8686 262.629 84.7357 266.729 86.0936C268.119 86.5349 269.542 86.9423 270.965 87.112C277.776 87.9267 284.418 87.2478 290.585 83.9889C295.567 81.375 299.701 77.8784 302.988 73.2277C305.597 69.5615 306.986 65.4539 308.308 61.2784C309.155 58.6306 311.324 57.1708 313.73 56.4919C316.78 55.6093 321.015 58.1553 322.201 61.2105C322.506 61.9913 322.744 62.7381 322.642 63.7226C322.168 68.8825 319.965 73.3975 317.593 77.8784C313.594 85.3807 307.664 91.0498 300.616 95.5308C295.363 98.8576 289.501 100.86 283.3 101.743C280.792 102.117 278.183 102.354 275.065 102.388Z"
        fill={color ? color : '#A0A0A0'}
      />
      <path
        d="M322.677 38.7389C322.846 43.4575 318.542 46.8862 313.595 45.9017C310.613 45.2907 308.953 43.3217 308.038 40.5381C307.123 37.7205 306.377 34.8011 304.785 32.2211C302.074 27.8759 298.821 24.1078 294.551 21.1544C290.247 18.201 285.503 16.334 280.42 15.689C276.625 15.2137 272.796 15.4513 269.001 16.334C260.393 18.3368 253.65 23.0215 248.77 30.388C246.974 33.1377 245.551 36.0911 244.772 39.35C243.958 42.7446 242.129 45.3586 238.401 46.0375C235.487 46.5806 231.421 44.408 230.099 41.4547C228.472 37.7884 230.438 34.9369 231.387 31.9835C232.369 28.7925 233.962 25.7372 235.792 22.8178C240.265 15.689 246.263 10.2575 253.548 6.21777C257.954 3.7736 262.664 2.0423 267.611 1.19363C272.321 0.378906 277.167 0.141278 281.911 0.752323C288.146 1.56705 294.212 3.36623 299.668 6.5233C305.496 9.88404 310.579 14.2292 314.578 19.7965C318.305 24.9565 320.982 30.5238 322.473 36.7021C322.643 37.3471 322.609 38.06 322.677 38.7389Z"
        fill={color ? color : '#A0A0A0'}
      />
      <path
        d="M185.064 51.2987C185.064 37.041 185.03 22.7833 185.064 8.55951C185.064 3.43352 188.622 0.242513 192.858 0.0727787C197.602 -0.16485 200.956 4.07852 200.923 7.81268C200.855 17.6912 200.956 27.5358 200.956 37.4144C200.99 53.3015 200.99 69.1887 200.956 85.0759C200.956 86.3998 201.228 86.9429 202.617 87.0108C204.514 87.1127 206.412 87.1127 208.31 87.1127C215.223 87.1127 222.102 87.1466 229.014 87.0108C233.081 86.9429 237.079 89.3871 237.384 94.6828C237.588 98.3152 235.317 101.608 231.217 102.66C230.065 102.966 228.845 103 227.625 103C216.781 102.966 205.904 103 195.06 102.932C193.196 102.932 191.299 102.932 189.537 102.049C186.046 100.25 184.996 97.1949 184.996 93.5626C185.03 79.4746 185.03 65.3867 185.064 51.2987C185.03 51.2987 185.064 51.2987 185.064 51.2987Z"
        fill={color ? color : '#A0A0A0'}
      />
      <path
        d="M132.88 102.388C126.746 102.117 121.256 101.472 115.936 99.537C106.821 96.2441 99.3998 90.7108 93.6052 82.9709C89.8099 77.8449 87.0651 72.2436 85.5063 66.0653C84.4898 61.9917 86.5229 58.1896 90.2504 57.0354C94.1813 55.8133 98.5526 57.8841 99.6708 61.8898C100.755 65.7598 102.111 69.46 104.381 72.7868C108.447 78.7954 113.869 83.1067 120.714 85.5848C127.187 87.9271 133.794 88.3005 140.335 86.4335C145.316 85.0077 149.958 82.6314 153.787 78.8972C157.617 75.1631 160.666 70.8858 162.564 65.8277C163.072 64.4698 163.411 63.044 163.852 61.6522C164.733 58.8346 166.766 57.2052 169.578 56.7978C172.289 56.4244 174.695 57.0694 176.559 59.4117C178.457 61.8559 178.355 64.4698 177.61 67.1516C175.949 73.296 173.035 78.7954 169.172 83.8195C165.58 88.5382 161.209 92.5099 156.159 95.4633C150.873 98.5864 145.112 100.929 138.877 101.676C136.675 101.981 134.506 102.219 132.88 102.388Z"
        fill={color ? color : '#A0A0A0'}
      />
      <path
        d="M131.929 0.649818C138.435 0.480083 144.602 1.9398 150.533 4.45187C155.073 6.38685 159.038 9.17049 162.833 12.3615C168.187 16.9104 172.152 22.3419 175.134 28.6221C176.49 31.4736 177.608 34.427 178.014 37.5162C178.76 43.1514 173.948 46.8855 168.865 45.6634C165.748 44.9166 164.324 42.8119 163.545 40.0282C162.325 35.5812 160.427 31.5076 157.479 27.9092C155.073 24.9898 152.43 22.3419 149.245 20.339C146.805 18.8114 144.23 17.6233 141.485 16.7746C133.827 14.3644 126.27 14.8057 118.917 17.8948C113.325 20.2372 108.954 24.0732 105.227 28.9615C102.143 33.0012 101.058 37.7538 99.0253 42.1669C97.1954 46.1726 90.4182 47.5645 87.0634 43.4908C85.1319 41.1145 84.9963 38.6364 85.6402 35.7849C87.0295 29.7423 89.9099 24.3108 93.5357 19.4564C97.0599 14.7378 101.465 10.7321 106.616 7.57499C112.851 3.77293 119.56 1.66823 126.744 0.853499C128.473 0.717712 130.201 0.717712 131.929 0.649818Z"
        fill={color ? color : '#A0A0A0'}
      />
      <path
        d="M38.6409 71.259C38.8443 62.7722 38.3699 54.2855 38.8443 45.7648C38.9459 43.9996 39.6237 42.5059 40.8436 41.2838C53.7882 28.2822 66.7328 15.2805 79.7113 2.31279C82.8288 -0.810326 87.6068 -0.742432 90.7243 2.34674C93.7402 5.36801 93.8419 10.2564 90.7243 13.4134C84.6248 19.5918 78.4236 25.7022 72.2901 31.8805C66.5633 37.6176 60.8365 43.3885 55.1097 49.1256C54.4659 49.7366 54.3304 50.4156 54.3304 51.2303C54.3304 65.6238 54.3642 80.0512 54.3304 94.4447C54.3304 98.2807 52.2294 101.37 48.9763 102.32C44.0967 103.848 38.6409 100.827 38.6748 94.3768C38.6748 87.0443 38.6748 79.7117 38.6748 72.3792C38.6409 72.0058 38.6409 71.6324 38.6409 71.259Z"
        fill={color ? color : '#A0A0A0'}
      />
      <path
        d="M7.83817 0.241341C10.0069 0.207394 11.8368 0.954226 13.3617 2.48184C21.0539 10.2217 28.78 17.9277 36.5061 25.6336C39.5897 28.7567 39.6236 33.6451 36.5738 36.6324C33.253 39.8913 28.475 39.6876 25.2219 36.3948C19.6306 30.6917 13.9377 25.0565 8.31258 19.3874C6.31328 17.3845 4.2801 15.3817 2.31469 13.3448C0.0104105 10.9346 -0.633432 8.08307 0.654252 4.95996C1.90805 1.97263 4.5173 0.309235 7.83817 0.241341Z"
        fill={color ? color : '#A0A0A0'}
      />
    </svg>
  </div>
}

export default Loader